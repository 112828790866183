import { Plan } from '@wix/ambassador-pricing-plans-v3-plan/types';
import { PlanCustomizationPublicData } from '@wix/pricing-plans-router-utils';
import type { CreateControllerFn, ControllerParams, ControllerFlowAPI, FlowAPI } from '@wix/yoshi-flow-editor';
import { DEMO_FORM_ID, plansFixtureTranslated } from '../../fixtures';
import { resolveLocale } from '../../utils';
import { createEcomCheckout, navigateToEcomCheckout } from '../../utils/ecom';
import { toError } from '../../utils/errors';
import { PlanCustomizationInteraction } from './fedops';
import { planV2ToDemoPlanV3 } from './mappers/plan-v2-to-demo-plan-v3';
import { FormsService } from './services/forms';
import { PlanCustomization } from './types';
import { RootProductPageProps } from './Widget';

const createController: CreateControllerFn = async ({ flowAPI }: ControllerParams) => {
  function reportInteractionStarted(name: PlanCustomizationInteraction) {
    flowAPI.panoramaClient.transaction(name).start();
  }

  function reportInteractionFinished(name: PlanCustomizationInteraction) {
    flowAPI.panoramaClient.transaction(name).finish();
  }

  return {
    async pageReady() {
      reportInteractionStarted(PlanCustomizationInteraction.Initialize);
      const wixCodeApi = flowAPI.controllerConfig.wixCodeApi;
      const setProps: (props: Partial<RootProductPageProps>) => void = flowAPI.controllerConfig.setProps;
      const formsService = new FormsService(flowAPI);

      setProps({
        status: 'PAGE_LOADING',
        formControllerStatus: 'LOADING',
        locale: resolveLocale(wixCodeApi),
        proceedToPayment,
        clearStatusMessage: () => setProps({ statusMessage: undefined }),

        // @ts-expect-error Undocumented viewer prop, which adds "height: auto" to root TPA section
        fitToContentHeight: true,
      });

      reportInteractionStarted(PlanCustomizationInteraction.GetProductPageData);
      const routerData = await getProductPageData(flowAPI);
      reportInteractionFinished(PlanCustomizationInteraction.GetProductPageData);
      const {
        plan,
        customizationData: { integrationData },
      } = routerData;

      reportInteractionStarted(PlanCustomizationInteraction.InitializeFormsController);
      formsService
        .maybeInitFormController(plan)
        .then(() => {
          reportInteractionFinished(PlanCustomizationInteraction.InitializeFormsController);
          setProps({ formControllerStatus: 'READY' });
        })
        .catch(() => {
          setProps({ formControllerStatus: 'ERROR' });
        });

      setProps({
        status: 'PAGE_READY',
        plan,
        minStartDate: integrationData.minStartDate ? integrationData.minStartDate : undefined,
        maxStartDate: integrationData.maxStartDate ? integrationData.maxStartDate : undefined,
      });

      reportInteractionFinished(PlanCustomizationInteraction.Initialize);

      async function proceedToPayment({ formValues, startDate }: PlanCustomization) {
        reportInteractionStarted(PlanCustomizationInteraction.ProceedToPayment);
        setProps({ status: 'CHECKOUT_LOADING', statusMessage: undefined });
        const submissionId = await formsService.maybeSubmitForm(plan.formId, formValues).catch((error) => {
          flowAPI.panoramaClient
            .errorMonitor()
            .reportError(toError(error), { tags: { interactionTag: 'proceed_to_payment' } });
          return null;
        });

        try {
          const checkout = await createEcomCheckout({
            flowAPI,
            plan,
            startDate,
            submissionId,
            integrationData,
          });
          await navigateToEcomCheckout({
            checkoutId: checkout.id!,
            integrationData,
            planId: plan.id!,
            wixCodeApi,
          });
          reportInteractionFinished(PlanCustomizationInteraction.ProceedToPayment);
        } catch (error) {
          const errorMessage = flowAPI.errorHandler.getResolvedError(error).message;
          setProps({ status: 'ERROR', statusMessage: errorMessage });
          flowAPI.panoramaClient.errorMonitor().reportError(toError(error), {
            tags: { interactionTag: PlanCustomizationInteraction.ProceedToPayment },
          });
        }
      }
    },
    async updateConfig() {},
  };
};

export default createController;

async function getProductPageData(flowAPI: ControllerFlowAPI): Promise<PlanCustomizationPublicData> {
  const routerData = flowAPI.controllerConfig.wixCodeApi.window.getRouterPublicData<PlanCustomizationPublicData>();
  if (!routerData) {
    if (flowAPI.environment.isEditor || flowAPI.environment.isPreview) {
      const demoPlan = getDemoPlanV3(flowAPI.translations.t);
      return {
        plan: demoPlan,
        customizationData: {
          integrationData: {},
          planId: demoPlan.id!,
        },
      };
    }

    // TODO: Add fallback client calls to get the data
    throw new Error('Router data is missing');
  }

  return routerData;
}

function getDemoPlanV3(t: FlowAPI['translations']['t']): Plan {
  const [planV2] = plansFixtureTranslated(t);
  return planV2ToDemoPlanV3({
    ...planV2,
    formId: DEMO_FORM_ID,
    allowFutureStartDate: true,
  });
}
